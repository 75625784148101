export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};
export const isDark = (theme) => theme.palette.mode === "dark";

// FOR LAYOUT 2 SECONDARY SIDEBAR
export const secondarySideBarGap = 80;
export const secondarySideBarWidth = 215;

export const RarityColors = {
  C: "#9AAFC5",
  UR: "#DEA54D",
  SR: "#A54DDD",
  UL: "#DC4D55",
  R: "#6860FF",
};
export const RarityFrames = {
  C: "/images/frame-C.png",
  UR: "/images/frame-UR.png",
  SR: "/images/frame-SR.png",
  UL: "/images/frame-UL.png",
  R: "/images/frame-R.png",
};
export const canvasScale = 8

export const Rarity = {
  Eternal: { title: 'Eternal', color: '#E33739', dailyEarn: 9.9, star: 5 },
  Divine: { title: 'Divine', color: '#8347D9', dailyEarn: 7.9, star: 4 },
  Mythic: { title: 'Mythic', color: '#FFF380', dailyEarn: 5.9, star: 3 },
  Rare: { title: 'Rare', color: '#44D7C6', dailyEarn: 3.9, star: 2 },
  Uncommon: { title: 'Uncommon', color: '#4485D7', dailyEarn: 1.9, star: 1 },
  Common: { title: 'Common', color: '#AFAFAF', dailyEarn: 0.9, star: 0 }
}