import { lazy } from "react";
import Loadable from "./Loadable";
import RootLayout from "../layouts/root/RootLayout";

const ErrorPage = Loadable(lazy(() => import("pages/404")));
const Homepage = Loadable(lazy(() => import("pages/root/homepage")));

export const routes = () => {
  return [
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          path: "",
          element: <Homepage />,
        },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ],
    },
  ];
};
